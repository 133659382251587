<div class="header-content">
    <div class="flex max-w-6xl mx-auto bg-white rounded-b-lg px-8 py-6">
        <img src="../assets/icons/esdp-branding.svg">
        <span class="text-lg font-bold ml-6 my-auto text-primary">Empowering ethnic minority businesses</span>
    </div>
    <div class="flex max-w-6xl bg-primary py-2 mx-auto gap-10">
        <img alt="esdp logo" class="logo-size" src="../assets/icons/esdp-logo.png">
        <h2 class="text-4xl text-white font-bold my-auto tracking-wide">{{header}}</h2>
        <div *ngIf="userName" class="flex-grow">
            <div class="flex justify-end text-white">
                <span class="font-bold mr-2">Welcome, </span>{{ userName }} | <span class="ml-2 pointer" (click)="logout()">Logout</span>
            </div>
        </div>
    </div>           
</div>