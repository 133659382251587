import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TokenService } from '../../token/token.service';
import { AuthService } from '../../services/auth/auth.service';

export interface TableData {
  revenueRange: string;
  feeExclVAT: number;
  feeInclVAT: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {

  dataSource: TableData[] = [
    {revenueRange: 'From 0 to 1', feeExclVAT: 180, feeInclVAT: 217.8},
    {revenueRange: 'From 1 to 5', feeExclVAT: 230, feeInclVAT: 278.3},
    {revenueRange: 'From 5 to 10', feeExclVAT: 350, feeInclVAT: 423.5},
    {revenueRange: 'from 10 upward', feeExclVAT: 540, feeInclVAT: 653.4},
    // Add more data as needed
  ];

  feeStructure: any[] = [
    {id: '1', revenueRange: 'From 0 to 1', description: 'Millions of € in Revenue', amount: '€180', image: 'assets/icons/esdp-logo.png', alt: 'ESDP Logo', title: 'ESDP Logo'},
    {id: '2', revenueRange: 'From 1 to 5', description: 'Millions of € in Revenue', amount: '€230', image: 'assets/icons/esdp-logo.png', alt: 'ESDP Logo', title: 'ESDP Logo'},
    {id: '3', revenueRange: 'From 5 to 10', description: 'Millions of € in Revenue', amount: '€350', image: 'assets/icons/esdp-logo.png', alt: 'ESDP Logo', title: 'ESDP Logo'},
    {id: '4', revenueRange: 'from 10 upward', description: 'Millions of € in Revenue', amount: '€540', image: 'assets/icons/esdp-logo.png', alt: 'ESDP Logo', title: 'ESDP Logo'}
  ];

  certificationEligiblityImages: any[] = [
    {id: '1', image: 'assets/images/ESDP_Certification_Launch_1.jpg', alt: 'ESDP Certification Launch', title: 'ESDP Certification Launch'},
    {id: '1', image: 'assets/images/ESDP_Certification_Launch_9.jpg', alt: 'ESDP Certification Launch', title: 'ESDP Certification Launch'},
    {id: '1', image: 'assets/images/ESDP_Certification_Launch_6.jpg', alt: 'ESDP Certification Launch', title: 'ESDP Certification Launch'},
    {id: '1', image: 'assets/images/ESDP_Certification_Launch_10.jpg', alt: 'ESDP Certification Launch', title: 'ESDP Certification Launch'},
    {id: '1', image: 'assets/images/ESDP_Certification_Launch_4.jpg', alt: 'ESDP Certification Launch', title: 'ESDP Certification Launch'}
  ];

  certificationEligiblityText: any[] = [
    {eligilibityText: 'The business is an <strong>ethnic minority owned business (EMB) as described in definitions.</strong>'},
    {eligilibityText: 'The business is a <strong>for-profit enterprise.</strong>'},
    {eligilibityText: 'The business is located & registered in the <strong>Netherlands, Germany or France.</strong>'},
    {eligilibityText: 'The relevant ethnic minority individuals considered to qualify as an EMB must be <strong>EU citizens</strong>  or hold <strong>5 year-visa/resident permit.</strong>'},
    {eligilibityText: '<a href="https://esdp-org.eu/wp-content/uploads/Supporting-Documents-required-for-ESDP-Certification.pdf" target="_blank"><strong><u>Supporting documents required for ESDP Certification</u></strong> need to be submitted.</a>'}
  ];

  definationArray: any[] = [
    {src: 'assets/images/Ethnic_Minority_Definition.png', alt: 'Ethnic Minority Definition', title: 'Ethnic Minority Definition', text: '“Ethnic Minority” Definition'},
    {src: 'assets/images/EMB_Definition.png', alt: 'Ethnic Minority Business Definition', title: 'Ethnic Minority Business Definition', text: '“Ethnic Minority Business” Definition'},
    {src: 'assets/images/Corporate_Members_Definition.png', alt: 'Corporate Members Definition', title: 'Corporate Members Definition', text: '“Corporate Members” Definition'},
  ];

  testimonials: any[] = [
    {id: '1', name: 'Peter Zerp', titleCompany: 'Supplier Inclusion & Diversity Client Program Lead, Accenture', testimonial: '“Team ESDP is a lean and powerful force. I am super impressed with each and every entrepreneur in the room, sharing their stories and amazing offerings. And thank-you to all our corporate peers, in the room and those who could not make it. This is a team effort and what a strong team we are!"', image: 'assets/images/Peter_Zerp.jpg', alt: 'Peter Zerp', title: 'Peter Zerp'},
    {id: '2', name: 'Luisa Boaretto', titleCompany: 'Head of Diversity, Equity and Inclusion and Employee Experience EMEAI, Dow', testimonial: '“Congratulations, ESDP, on achieving the certification for Ethnic Minority Businesses (EMBs) in Europe. As Supplier Diversity gains traction in our region, having rigorous and reliable certification bodies is essential. We look forward to continuing our partnership and accelerating this journey, positively impacting people, communities, and businesses."', image: 'assets/images/Luisa_Boaretto.jpg', alt: 'Luisa Boaretto', title: 'Luisa Boaretto'},
    {id: '3', name: 'Vivian Acquah CDE®', titleCompany: 'Certified DEI Trainer, Founder, Amplify DEI', testimonial: '“Receiving the ESDP Certification validates the hard work and dedication of everyone at Amplify DEI (including those we collaborate with), reinforcing our commitment to continue pushing boundaries and making significant strides in the DEI domain."', image: 'assets/images/Vivian_Acquah_CDE®.jpg', alt: 'Vivian Acquah CDE®', title: 'Vivian Acquah CDE®'},
    {id: '4', name: 'Axelle Ahanhanzo', titleCompany: 'Founder, LAUDACE', testimonial: '“It is truly an honor to be at the forefront of EMB Supplier Diversity in Europe, I am thrilled to receive the ESDP Certification for my contributions in the Dutch DEI landscape, and eager to see the positive impact that this partnership with ESDP and its members will yield."', image: 'assets/images/Axelle_Ahanhanzo.jpg', alt: 'Axelle Ahanhanzo', title: 'Axelle Ahanhanzo'},
    {id: '5', name: 'Lucas Köhncke', titleCompany: 'Chief Operating Officer, Bosenet Systemhaus GmbH & Co. KG', testimonial: '“Getting to engage in meaningful conversations highlighted what an incredible network ESDP has built. Each interaction only served to deepen my appreciation for the diverse talents, perspectives and opportunities the network offers."', image: 'assets/images/Lucas_Köhncke.jpeg', alt: 'Lucas Köhncke', title: 'Lucas Köhncke'},
    {id: '6', name: 'Nelson T. Ajulo, PhD', titleCompany: 'Founder & CEO, Zarttech', testimonial: '“Ethnic minorities face substantial underrepresentation in businesses because of bias and limited opportunities for advancement. It was remarkable to witness the diverse perspectives and expertise come together at ESDP Certification Launch Event to tackle one of the most pressing challenges in our society.”', image: 'assets/images/Nelson_T_Ajulo_PhD.jpeg', alt: 'Nelson T. Ajulo, PhD', title: 'Nelson T. Ajulo, PhD'},
  ]

  displayedColumns: string[] = ['revenueRange', 'feeExclVAT'];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [ "<img src='assets/icons/esdp-logo.png' alt='Left Arrow' class='w-[6%] rotate-180'>",
      "<img src='assets/icons/esdp-logo.png' alt='Right Arrow' class='w-[6%]'>"],
    responsive: {
      0: {
        items: 3
      }
    },
    nav: true
  };

  certificationEligilibityOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 900,
    navText: [ "<i class='material-icons'>chevron_left</i>",
      "<i class='material-icons'>chevron_right</i>"],
    nav: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 2000
  };

  testimonialsOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [ "<img src='assets/icons/esdp-logo.png' alt='Left Arrow' class='w-[6%] rotate-180'>",
      "<img src='assets/icons/esdp-logo.png' alt='Right Arrow' class='w-[6%]'>"],
    nav: true,
    responsive: {
      0: {
        items: 3
      }
    },
  }


  constructor(private router: Router, private tokenService: TokenService, private authService: AuthService) {}

  ngOnInit(): void {
    this.tokenService.updateToken(null);
    this.logout();
  }

  async logout() {
    try {
    const response = await this.authService.logout();
    if (response) {
      if (response.success) {
        sessionStorage.clear();
        localStorage.clear();
      }
    }
  } catch (error) {
    console.error('Unexpected error:', error);
    // Handle unexpected errors globally (optional)
  }
  }

  goToCreateAccount() {
    this.router.navigate(['/register']);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
