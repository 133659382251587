import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from '../../services/checkout/checkout.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrl: './success.component.css'
})
export class SuccessComponent {
  appId: any;
  paymentInfo: { isPaid: boolean, txn_id?: string } = { isPaid: false, txn_id: ''};
  isLoading: boolean = true;
  private checkPaidStatusInterval: any;
  private checkCount: number = 0;
  supportEmail = 'support@esdp-org.eu';

  constructor(private route: ActivatedRoute, private checkoutService: CheckoutService, public router: Router) {
    this.isLoading = true;
   }

  ngOnInit() {
    sessionStorage.removeItem('appId');
    localStorage.removeItem('appId');
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.appId = params['id'];
        this.checkPaidStatusInterval = setInterval(() => {
          this.checkPaymentStatus(this.appId);
        }, 3000);
      }
    });
  }

  async checkPaymentStatus(appId: any) {
    const response = await this.checkoutService.checkPaymentStatus(appId);
    if (response.current_status === "PAID") {
      this.paymentInfo.isPaid = true;
      this.paymentInfo.txn_id = response.txn_id;
      this.isLoading = false;
      clearInterval(this.checkPaidStatusInterval);
    } else {
      this.checkCount++;
      if (this.checkCount >= 10) {
        clearInterval(this.checkPaidStatusInterval);
        this.paymentInfo.txn_id = 'pending';
        this.isLoading = false;
      }
    }
  }

}
