<div class="flex items-end justify-end mb-5">
  <button
    mat-raised-button
    class="text-primary back-btn"
    (click)="goToLogin()"
  >
    <mat-icon class="mat-18"
      ><span class="material-symbols-outlined"> arrow_back </span></mat-icon
    >Go back to Login 
  </button>
</div>
<mat-card class="pb-10">
  <mat-card-content>
    <div class="py-20 flex justify-center">
      <div class="w-1/2">
        <div class="mb-3 text-xl font-bold">Forgot Password</div> 
        <div class="text-base mb-3 lg:text-lg font-medium">
          Email Address <sup>*</sup>
        </div>
        <mat-form-field class="w-full" appearance="outline">
          <input matInput type="email" placeholder="Please enter your email address" [formControl]="email" />
          <mat-error
            *ngIf="email?.touched && email?.hasError('required')">
            Email is required</mat-error>
          <mat-error *ngIf="email?.touched && email?.hasError('email')">
            Email must be a valid Email</mat-error>
        </mat-form-field>
        <div class="button flex justify-end">
          <button type="submit" mat-raised-button class="mat-blue" [disabled]="!email.valid || disableButton" (click)="resetPassword()">Send Reset Password Link</button>
        </div>
    
        <div class="error-container" *ngIf="errorMessage">
          <span>{{ errorMessage }}</span>
          <button mat-icon-button (click)="clearError()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

