import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'esdp-ui-v1';
  pageTitle = '';
  isLandingPage: boolean = false;

  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const routeTitle = this.getTitleFromRoute(this.router.routerState, this.router.routerState.root);
        this.titleService.setTitle(routeTitle);
        this.pageTitle = routeTitle;
        this.isLandingPage = event.urlAfterRedirects.startsWith('/landing');
      }
    });
  }

  private getTitleFromRoute(state: any, parent: any): string {
    const data = parent && parent.snapshot.data;
    if (data && data.title) {
      return data.title;
    }
    return state && parent ? this.getTitleFromRoute(state, state.firstChild(parent)) : 'Default Title';
  }

}
