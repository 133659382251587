import { CanActivate, Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { take, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {

constructor(private auth: AngularFireAuth, private router: Router) { }

canActivate() {
    return this.auth.authState.pipe(
        take(1),
        switchMap(async (authState) => {
            if (!authState) { // check are user is logged in
                this.router.navigate(['/login'])
                return false
            }
            const token = await authState.getIdTokenResult()
            if (token.claims['admin']) { // check claims
                this.router.navigate(['/unauthorized'])
                return false
            }
            return true
        }),
    )
}
}
