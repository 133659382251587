<!-- success.component.html -->

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <div *ngIf="!isLoading &&  paymentInfo.isPaid" class="flex justify-start items-center">
                <mat-icon class="checkmark">check_circle</mat-icon>
                <div class="ml-5 text-green text-2xl font-semibold">Application Submitted Successfully</div>
            </div>
            @if (isLoading || !paymentInfo.txn_id) {
                <div class="flex justify-start items-center">
                    <mat-icon class="pending">schedule</mat-icon>
                    <div class="ml-5 text-pending text-2xl font-semibold">Transaction is Processing!</div>
                </div>
            } @else if (!isLoading && paymentInfo.txn_id === 'pending') {
                <div class="flex justify-start items-center">
                    <mat-icon class="warning">warning</mat-icon>
                    <div class="ml-5 text-red text-2xl font-semibold">Status check still pending!</div>
                </div>
            }
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="my-10 text-lg">
        <div *ngIf="isLoading || !paymentInfo.txn_id">
            <p class="mb-1">Please wait, transaction is processing...</p>
            <p>Please don't refresh the page or go back.</p>
        </div>
        <div *ngIf="!isLoading &&  paymentInfo.isPaid && paymentInfo.txn_id !== 'exempted' && paymentInfo.txn_id !== 'pending'">
            <p class="mb-3">Your payment with Transaction ID: <span class="font-bold text-lg">{{ paymentInfo.txn_id }} </span>has been successfully processed.</p>
            <p>Thank you for submitting your application!</p>
            <p>You will hear back from us soon -Team ESDP</p>
            <p>Support email : {{supportEmail}}</p>
        </div>
        <div *ngIf="!isLoading &&  paymentInfo.isPaid && paymentInfo.txn_id === 'exempted'">
            <p class="mb-3">Your transaction has been successfully processed with payment exemption.</p>
            <p>Thank you for submitting your application</p>
            <p>You will hear back from us soon -Team ESDP</p>
            <p>Support email : {{supportEmail}}</p>
        </div>
        <div *ngIf="!isLoading && paymentInfo.txn_id === 'pending'">
            <p class="mb-3">Having trouble fetching your transaction status!</p>
            <p>Please go to your dashboard and check the status. Retry via the "Pay Now" option if not paid.</p>
            <p>Support email : {{supportEmail}}</p>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button *ngIf="(!isLoading && paymentInfo.isPaid) || paymentInfo.txn_id === 'pending'" mat-button color="primary" routerLink="/certifications">Back to Login/Dashboard</button>
    </mat-card-actions>
</mat-card>