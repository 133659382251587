import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TokenService } from '../../../token/token.service';
import { jwtDecode } from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  @Input() header!: string;
  userName: string | null = null;
  private tokenSubscription!: Subscription;

  constructor(private tokenService: TokenService, private authService: AuthService,
    private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.tokenSubscription = this.tokenService.getTokenObservable().subscribe(token => {
      if (token) {
        const decodedToken = this.decodeToken(token);
        this.userName = decodedToken ? decodedToken.name : null;
      } else {
        this.userName = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
  }

  decodeToken(token: string): any {
    return jwtDecode(token);
  }

  async logout() {
    try {
    const response = await this.authService.logout();
    if (response) {
      if (response.success) {
        this.router.navigate(['/login']);
        this.userName = '';
        sessionStorage.clear();
        localStorage.clear();
        this.toastr.success('Successfully Logged out', '');
      } else {
        // this.errorMessage = response.response.message;
         // Display error message
      }
    }
  } catch (error) {
    console.error('Unexpected error:', error);
    // Handle unexpected errors globally (optional)
  }
  }
}
