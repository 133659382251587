import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
  email = new FormControl('', [Validators.required, Validators.email]);
  disableButton = false;
  errorMessage = '';

  constructor(private authService: AuthService, private router: Router) {}

  goToLogin() {
    this.router.navigate(['/login']);
  }

  async resetPassword() {
    this.disableButton = true;
    try {
    const response = await this.authService.forgotPassword(this.email?.value);
    if (response) {
      this.disableButton = false;
      if (response.success) {
        this.router.navigate(['/login']);
      } else {
        this.errorMessage = response.response.message;
         // Display error message
      }
    }
  } catch (error) {
    console.error('Unexpected error:', error);
    // Handle unexpected errors globally (optional)
  }
  }

  clearError() {
    this.errorMessage = '';
  }
}
