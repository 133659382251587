<!-------Launch Banner----------->
<section>
  <div class="relative">
    <div class="relative">
      <img rel="preload" fetchpriority="high" as="image" src="assets/images/ESDP_Certification_Launch_1.jpg" alt="ESDP Certification Launch" title="ESDP Certification Launch" class="w-full h-auto">
      <svg width="2880" height="300" viewBox="0 0 2880 392" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-full absolute bottom-[-4.1rem]">
        <path d="M2880 83.333C2224.88 500.156 804.08 -236.156 0 83.333V400H2880V83.333Z" fill="white"/>
      </svg>
    </div>

    <div class="absolute bottom-56 left-8 w-3/5  translate-x-[2%] translate-y-[40%] bg-transperantwhite p-6 rounded-2xl launch-event-banner-box-shadow">
      <div class="text-3xl font-bold tracking-wide text-primary">ESDP is Europe's first Supplier Diversity advocacy network,</div>
      <div class="mt-3 mb-4 tracking-wide">headquartered in <strong>Amsterdam</strong>, certifying, and connecting <strong>ethnic minority and immigrant-owned businesses</strong> with <strong>global and regional corporations.</strong> </div>
      <div class="tracking-wide">Our mission is to help our beneficiaries to sustain and grow by doing business with global corporates, committed to a more diverse & inclusive supply chain.</div>
      <div class="flex justify-center gap-5 items-center mt-5">
        <button mat-raised-button class="mat-blue !rounded-[50px] !px-7" (click)="goToCreateAccount()">Create Account</button>
        <div>Or</div>
        <button mat-raised-button class="mat-blue !rounded-[50px] !px-7" (click)="goToLogin()">Log In</button>
      </div>
    </div>
  </div>
</section>

<!--------What is esdp certification------------>
<section class="pb-16 bg-gradient-to-b from-white to-greytestimonial">
  <div class="text-white bg-primary p-9 rounded-2xl max-w-6xl 2xl:max-w-7xl mx-auto mt-[-14px] z-10 relative">
    <div class="flex items-center gap-4">
      <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-6">
      <div class="text-2xl font-bold tracking-wide">What is ESDP Certification?</div>
    </div>
    <div class="ml-11 tracking-wide">
      <div class="mt-5 mb-4">
        <strong>Certification vs. Self-Registered Membership.</strong> Businesses that wish to be on our supplier database accessible by our Corporate Members can be either <strong>Certified Members or Self-Registered Members.</strong>
      </div>
      <div>
        By choosing to be a Certified Member, you can benefit, for a fee, from enhanced visibility to our Corporate Members and increased access to business opportunities.
      </div>
    </div>
  </div>
</section>

<!---fee structure-->
<section class="bg-greytestimonial">
  <div class="flex flex-row justify-between items-center bg-primary pl-[3.25rem] xl:pl-[7.5rem] pr-10 w-2/5 rounded-r-lg">
    <div class="text-white text-3xl font-bold tracking-wide">Annual Membership Fee</div>
    <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-11">
  </div>
  <div class="max-w-6xl 2xl:max-w-7xl mx-auto py-16">
    <div class="flex flex-row gap-4">
      <div class="bg-primary text-white rounded-2xl p-7 w-1/4 relative">
          <div class="text-2xl">ESDP Certification <br> <span class="font-bold tracking-wide">Fee Structure</span></div>
          <div class="tracking-wide w-3/5 my-3">(Non-refundable Processing Fee, exc. VAT 21%)</div>
          <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-7">
        <img src="assets/images/Paloma_photo.png" alt="Paloma" title="Paloma" class="w-[46%] absolute bottom-0 right-0">
      </div>
      <div class="w-3/4 mb-[-10px]">
        <owl-carousel-o [options]="customOptions" class="feeStructure-carousel">
          <ng-container *ngFor="let slide of feeStructure">
            <ng-template carouselSlide [id]="slide.id" >
              <mat-card class="p-6 !rounded-2xl my-1 mx-5 h-[255px]">
                <div class="flex flex-row items-center gap-6">
                  <img [src]="slide.image" [alt]="slide.alt" [title]="slide.title" class="!w-5">
                  <div class="text-xl font-bold">{{slide.revenueRange}}</div>
                </div>
                <div class="text-xl font-bold pt-7 pb-4 border-b-2 border-dotted">{{slide.description}}</div>
                <div class="text-3xl font-bold py-5">{{slide.amount}}</div>
              </mat-card>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<!---certification Eligilibity------>
<section class="bg-primary py-16">
  <div class="flex flex-row justify-between items-center bg-white pl-[3.25rem] xl:pl-[7.5rem] pr-10 w-2/5 rounded-r-lg mb-8">
    <div class="text-primary text-3xl font-bold tracking-wide">Certification Eligibility</div>
    <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-11">
  </div>
  <!-------Carousel Portion---------->
  <div class="max-w-6xl 2xl:max-w-7xl mx-auto">
    <div class="flex flex-row h-full">
      <div class="w-1/2 !rounded-l-xl">
        <owl-carousel-o [options]="certificationEligilibityOptions" class="eligilibility-carousel">
          <ng-container *ngFor="let slide of certificationEligiblityImages">
            <ng-template carouselSlide [id]="slide.id" >
              <img [src]="slide.image" [alt]="slide.alt" [title]="slide.title" class="!rounded-l-xl w-full h-[450px] object-cover">
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <div class="w-1/2 bg-white rounded-r-xl p-6 h-[450px]">
        <div class="text-primary text-2xl font-bold leading-8 pl-9">
          To be eligible for ESDP Certification, applicants must declare they meet all of these following criteria:
        </div>
        <ul class="text-lg leading-5">
          <li class="pt-5 flex flex-row gap-3" *ngFor="let eligibility of certificationEligiblityText">
            <div class="flex-shrink-0">
              <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-6 h-6 object-contain">
            </div>
            <span [innerHTML]="eligibility.eligilibityText"></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!--------Definations--------------->
<section class="bg-greytestimonial pt-16">
  <div class="flex flex-row justify-between items-center bg-primary pl-[3.25rem] xl:pl-[7.5rem] pr-10 w-2/5 rounded-r-lg">
    <div class="text-white text-3xl font-bold tracking-wide">Definitions</div>
    <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-11">
  </div>
  <div class="max-w-6xl 2xl:max-w-7xl mx-auto mt-16 pb-16">
    <div class="flex flex-row flex-grow items-center gap-16">
      <div class="flex flex-col items-center justify-between rounded-t-lg" *ngFor="let defination of definationArray">
        <img [src]="defination.src" [alt]="defination.alt" [title]="defination.title" class="rounded-t-xl">
        <mat-card class="w-full py-7 text-center text-primary text-xl font-bold !rounded-b-xl h-24">{{defination.text}}</mat-card>
      </div>
    </div>
  </div>
</section>

<!----Processing of Data------------->
<section class="bg-primary py-16">
  <div class="flex flex-row justify-between items-center bg-white pl-[3.25rem] xl:pl-[7.5rem] pr-10 w-2/5 rounded-r-lg mb-8">
    <div class="text-primary text-3xl font-bold tracking-wide">Processing of your Data</div>
    <img src="assets/icons/esdp-logo.png" alt="Esdp Logo" title="Esdp Logo" class="w-11">
  </div>
  <div class="max-w-6xl 2xl:max-w-7xl mx-auto bg-white rounded-xl p-7 text-lg">
    <p class="mb-8">Please note that some special categories of data, constituting sensitive data, are processed by us as part of our purpose to provide assistance to the most discriminated ethnic minorities who run businesses and give them visibility to our Corporate Members who are committed to equal opportunities, diversity and inclusion.</p>
    <p class="mb-8">To find out, amongst others, specifically why and how we process your data, please read this entire section along with our <a href="https://esdp-org.eu/privacy/?_gl=1*1ozeso6*_ga*NDQyMDMxMTM0LjE2OTk1MjE5NTI.*_ga_KNQDF1YSTT*MTcxMTAzMDkzOC45NC4xLjE3MTEwMzEwNjUuMC4wLjA."
      target="_blank" class="underline underline-offset-2 cursor-pointer">Privacy Policy</a> and the <a href="https://esdp-org.eu/faqs/?_gl=1*1f01e3n*_ga*NDQyMDMxMTM0LjE2OTk1MjE5NTI.*_ga_KNQDF1YSTT*MTcxMTAzMDkzOC45NC4xLjE3MTEwMzEwNjguMC4wLjA."
      target="_blank" class="underline underline-offset-2 cursor-pointer">FAQs.</a></p>
    <strong>Terms used in this document</strong>
    <p  class="mt-8">The term "ethnic minority" is used here. We understand, accept and embrace the fact that human beings constitute a single race. For the sake of this document and our actions, we must acknowledge, without condoning or agreeing with it, the fact that large sections of the population are discriminated against due to what is perceived as a different "race" / “ethnicity”. We also note that "race" is a term used by laws and constitutions in Europe and beyond to refer to different groups of human beings. As such, in order to identify the groups of people who are subject to discrimination, based on their non-European white physical appearance or non-white European sounding name, we may use the term "race", "racial", "ethnicity" and "ethnic" in our communication.</p>
  </div>
</section>

<!----Testimonials----------->
<section class="py-16 bg-greytestimonial">
  <div class="max-w-6xl 2xl:max-w-7xl mx-auto ">
    <owl-carousel-o [options]="testimonialsOptions" class="testimonial-carousel">
      <ng-container *ngFor="let testimonial of testimonials">
        <ng-template carouselSlide [id]="testimonial.id" >
          <mat-card class="p-7 !rounded-2xl my-4 mx-6">
            <div class="flex flex-row gap-6 items-start grow h-24">
              <div class="flex flex-col gap-1 w-3/4">
                <div class="text-2xl font-bold">{{testimonial.name}}</div>
                <div class="text-primary text-sm font-bold">{{testimonial.titleCompany}}</div>
              </div>
              <div class="w-1/4"><img [src]="testimonial.image" [alt]="testimonial.alt" [title]="testimonial.title" class="w-full rounded-full"></div>
            </div>
            <div class="font-bold text-md pt-5 leading-loose h-[22rem]"> {{testimonial.testimonial}} </div>
          </mat-card>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</section>
