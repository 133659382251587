import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DbService } from '../../services/db/db.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../../token/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  showPassword = false;
  disableButton = false;
  errorMessage = '';

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  constructor(private authService: AuthService, private router: Router, private tokenService: TokenService,
    private fb: FormBuilder, private dbService: DbService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.tokenService.updateToken(null);
    this.logout();
  }

  public async createPaymentTier(): Promise<void> {
    const paymentBasedOnTurnover = [{
      "active": true,
      "email": "mukh.ayan96@gmail.com",
      "corporate_name": "ESDP",
      "added_on": "15:03.2024",
      "added_by": "ESDP Admin",
      "used_on": ""
    },
    // {
    //  "active": true,
    //   "email": "jenny@email.com",
    //   "corporate_name": "ESDP",
    //   "added_on": "15:03.2024",
    //   "added_by": "ESDP Admin",
    //   "used_on": "17.03.2014"
    // }
]
      // {

      //   "key": "Less than €1 million",
      //   "value": "less_than_1_million",
      //   "price": 180
      // },
      // {

      //   "key": "€1 million - €5 million",
      //   "value": "1m_to_5m",
      //   "price": 230
      // },
      // {

      //   "key": "€5 million - €10 million",
      //   "value": "5m_to_10m",
      //   "price": 350
      // },
      // {

      //   "key": "€10 million - €50 million",
      //   "value": "10m_to_50m",
      //   "price": 540
      // },
      // {

      //   "key": "€50 million - €100 million",
      //   "value": "50m_to_100m",
      //   "price": 540
      // },
      // {

      //   "key": "€100 million and above",
      //   "value": "100m_and_above",
      //   "price": 540
      // }
    // ];

    try {
      for (const option of paymentBasedOnTurnover) {
        await this.dbService.createPaymentTier(option);
      }
      console.log("All payment tiers saved to Firestore!");
    } catch (error) {
      console.error("Error saving payment tiers to Firestore:", error);
    }
  }

  async logout() {
    try {
    const response = await this.authService.logout();
    if (response) {
      if (response.success) {
        sessionStorage.clear();
        localStorage.clear();
      }
    }
  } catch (error) {
    console.error('Unexpected error:', error);
    // Handle unexpected errors globally (optional)
  }
  }

  async onSubmit() {
    // this.isLoading = true;
    this.disableButton = true;
    const email: any = this.loginForm.get('email')?.value;
    const password: any = this.loginForm.get('password')?.value;
    try {
      const response = await this.authService.signIn(email, password);
      if (response) {
        if (response.success) {
          this.isLoading = false;
          this.toastr.success('Successfully logged in', '');
        } else {
          this.disableButton = false;
          this.isLoading = false;
          this.errorMessage = response.response.message || response.response; // Display error message
        }
      }
    } catch (error) {
      this.disableButton = false;
      this.isLoading = false;
    }
  }

  clearError() {
    this.errorMessage = '';
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  gotoSignUpPage(): void {
    this.router.navigate(['/register']);
  }

  goToLanding() {
    this.router.navigate(['/']);
  }
}
