import { Routes } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { RegisterComponent } from '../components/register/register.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { AuthGuard } from '../services/auth-guard/auth.guard';
import { SuccessComponent } from '../components/success/success.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AdminGuard } from '../services/auth-guard/admin.guard';
import { UnauthorizedComponent } from '../components/unauthorized/unauthorized.component';


export const SECURE_ROUTES: Routes =[
  {
    path: '',
    data: {title: 'Dashboard'},
    pathMatch:'full',
    redirectTo: 'landing'

  },
  {
    path: 'landing',
    data: {title: 'ESDP Certification'},
    component: DashboardComponent
  },
  {
    path: 'login',
    data: {title: 'Login to ESDP'},
    component: LoginComponent
  },
  {
    path: 'register',
    data: {title: 'Create Account'},
    component: RegisterComponent
  },
  {
    path: 'forgot-password',
    data: {title: 'Forgot Password'},
    component: ForgotPasswordComponent
  },
  {
    path: 'success/:id',
    data: {title: 'Transaction Status Page'},
    component: SuccessComponent
  },
  {
    path: 'certifications',
    data: {title: 'Apply for Certification'},
    // data: {title: 'LoginComponent',roles: ['ROLE_BK', 'ROLE_EMP']},
    canActivate: [AuthGuard],
    loadChildren: () => import('../modules/certification/certification.module').then((m) => m.CertificationModule),
  },
  {
    path: 'admin',
    data: {title: 'Admin dashboard'},
    // data: {title: 'LoginComponent',roles: ['ROLE_BK', 'ROLE_EMP']},
    canActivate: [AdminGuard],
    loadChildren: () => import('../modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'unauthorized', // Add route for unauthorized page
    data: {title: 'Unauthorized'},
    component: UnauthorizedComponent,
  }
]
