<div class="flex items-end justify-end mb-5">
  <button
    mat-raised-button
    [disabled]="isCreateAccountInProgress"
    class="text-primary back-btn"
    (click)="goToLanding()"
  >
    <mat-icon class="mat-18"
      ><span class="material-symbols-outlined"> arrow_back </span></mat-icon
    >Go back to EMB portal 
  </button>
</div>
<div class="flex flex-row max-w-6xl mx-auto mt-5 mb-10 rounded-md border border-grey-500">
  <div class="basis-1/3 bg-lightBlue text-primary">
    <div class="flex justify-center pt-60 font-bold text-2xl">Start a new Journey!</div>
    <div class="flex flex-col items-center pt-80 gap-5 font-bold text-xl">
      <div>Already have an account?</div>
      <div>
        <button type="submit" mat-raised-button class="mat-blue" (click)="gotoLogInPage()">Log In</button>
      </div>
    </div>
  </div>
  <div class="basis-2/3 p-10 text-primary">
    <h1 class="text-3xl mb-7 font-bold">Create Account</h1>

    <form [formGroup]="registerForm">
      <div class="flex flex-col mb-3 lg:flex-row lg:gap-10">
        <div class="basis-1/2">
          <!--First Name-->
          <div class="text-base mb-3 lg:text-lg font-medium">
            First Name <sup>*</sup>
          </div>
          <mat-form-field class="w-full" appearance="outline" hintLabel="Max 50 characters">
            <input matInput type="text" maxlength="50" #firstName placeholder="Please enter your first name" formControlName="firstname" appNonNumeric/>
            <mat-hint align="end">{{firstName.value.length}} / 50</mat-hint>
            <mat-error
              *ngIf="this.registerForm.get('firstname')?.touched && this.registerForm.get('firstname')?.hasError('required')">
              First Name is required</mat-error>
            <mat-error
              *ngIf="this.registerForm.get('firstname')?.touched && this.registerForm.get('firstname')?.hasError('minlength')">
              Minimum length is 2 Characters</mat-error>
          </mat-form-field>
        </div>
        <div class="basis-1/2">
          <!--Last Name-->
          <div class="text-base mb-3 lg:text-lg font-medium">
            Last Name <sup>*</sup>
          </div>
          <mat-form-field class="w-full" appearance="outline" hintLabel="Max 50 characters">
            <input matInput type="text" maxlength="50" #lastName placeholder="Please enter your last name" formControlName="lastname" appNonNumeric/>
            <mat-hint align="end">{{lastName.value.length}} / 50</mat-hint>
            <mat-error
              *ngIf="this.registerForm.get('lastname')?.touched && this.registerForm.get('lastname')?.hasError('required')">
              Last Name is required</mat-error>
            <mat-error
              *ngIf="this.registerForm.get('lastname')?.touched && this.registerForm.get('lastname')?.hasError('minlength')">
              Minimum length is 2 Characters</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--Org Name-->
      <div class="flex flex-start gap-3">
        <div class="text-base mb-3 lg:text-lg font-medium">
          Name of the Organisation on whose behalf the certification is sought <sup>*</sup> 
        </div>
        <mat-icon class="cursor-pointer" matTooltip="Registered business name">info</mat-icon>
      </div>
      <mat-form-field class="w-full mb-3" appearance="outline" hintLabel="Max 150 characters">
        <input matInput type="text" maxlength="150" #orgName placeholder="Please enter your organisation name" formControlName="orgname" />
        <mat-hint align="end">{{orgName.value.length}} / 150</mat-hint>
        <mat-error
          *ngIf="this.registerForm.get('orgname')?.touched && this.registerForm.get('orgname')?.hasError('required')">
          Organisation Name is required</mat-error>
        <mat-error
          *ngIf="this.registerForm.get('orgname')?.touched && this.registerForm.get('orgname')?.hasError('minlength')">
          Minimum length is 2 Characters</mat-error>
      </mat-form-field>

      <!--Email-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Email Address <sup>*</sup>
      </div>
      <mat-form-field class="w-full mb-3" appearance="outline">
        <input matInput type="email" placeholder="Please enter your email address" formControlName="email" />
        <mat-error
          *ngIf="this.registerForm.get('email')?.touched && this.registerForm.get('email')?.hasError('required')">
          Email is required</mat-error>
        <mat-error *ngIf="this.registerForm.get('email')?.touched && this.registerForm.get('email')?.hasError('email')">
          Email must be a valid Email</mat-error>
      </mat-form-field>

      <!--Confirm Email-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Confirm Email Address <sup>*</sup>
      </div>
      <mat-form-field class="w-full mb-3" appearance="outline">
        <input matInput type="email" placeholder="Please enter your email address" formControlName="email_confirm" />
        <mat-error
          *ngIf="this.registerForm.get('email_confirm')?.touched && this.registerForm.get('email_confirm')?.hasError('required')">
          Confirm Email is required</mat-error>
        <mat-error
          *ngIf="this.registerForm.get('email_confirm')?.touched && this.registerForm.get('email_confirm')?.hasError('email_confirm')">
          Confirm Email must be a valid Email</mat-error>
      </mat-form-field>
      <mat-error class="mb-3"
        *ngIf="this.registerForm.get('email_confirm')?.dirty && this.registerForm.hasError('emailsNotMatching')
        && !this.registerForm.get('email_confirm')?.hasError('required')">
        Emails are not matching!</mat-error>

      <!--Password-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Password <sup>*</sup>
      </div>
      <mat-form-field class="w-full mb-3" appearance="outline" hintLabel="Min 8 characters">
        <input matInput #pass [type]="showPassword ? 'text' : 'password'" placeholder="Please create a password" formControlName="password" />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">
          {{showPassword?'visibility_off':'visibility'}}
        </mat-icon>
        <mat-error
          *ngIf="this.registerForm.get('password')?.touched && this.registerForm.get('password')?.hasError('required')">
          Password is required</mat-error>
        <mat-error
          *ngIf="this.registerForm.get('password')?.touched && this.registerForm.get('password')?.hasError('minlength')">
          Minimum length is 8 Characters</mat-error>
      </mat-form-field>

      <!--Confirm Password-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Confirm Password <sup>*</sup>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <input matInput [type]="showConfirmPassword ? 'text' : 'password'" placeholder="Please confirm the password" formControlName="password_confirm" />
        <mat-icon matSuffix (click)="toggleConfirmPasswordVisibility()">
          {{showConfirmPassword?'visibility_off':'visibility'}}
      </mat-icon>
        <mat-error
          *ngIf="this.registerForm.get('password_confirm')?.touched && this.registerForm.get('password_confirm')?.hasError('required')">
          Password Confirmation is required</mat-error>
      </mat-form-field>
      <mat-error
        *ngIf="this.registerForm.get('password_confirm')?.dirty && this.registerForm.hasError('passwordsNotMatching')
        && !this.registerForm.get('password_confirm')?.hasError('required')">
        Passwords are not matching!</mat-error>

      <!--Country Code-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Phone number <sup>*</sup>
      </div>
      <div class="flex gap-5">
        <mat-form-field class="w-2/5" appearance="outline">
          <mat-select
          placeholder="Country code"
          formControlName="countryCode"
          (openedChange)="onDropdownOpen($event)"
          >
            @for (options of countryCodes; track options) {
            <mat-option [value]="options.value">{{ options.key }}</mat-option>
            }
          </mat-select>
          <mat-error
            *ngIf="this.registerForm.get('countryCode')?.touched && this.registerForm.get('countryCode')?.hasError('required')">
            Country code is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-3/5" appearance="outline">
          <input matInput placeholder="Please enter phone number" formControlName="phoneNumber"
            appNumeric [minValue]="1" [maxValue]="1000000000000000" />
          <mat-error
            *ngIf="this.registerForm.get('phoneNumber')?.touched && this.registerForm.get('phoneNumber')?.hasError('required')">
            Phone number is required</mat-error>
        </mat-form-field>
      </div>

      <!-- <mat-checkbox class="text-primary font-medium -ml-3 mb-4 -mt-2" color="primary" formControlName="facingDisadvantage">
        <div class="text-primary font-medium text-base mt-5">
          Tick here if you consider of being disadvantaged in your business as a result of your visible racial/ethnic background.
        </div>
      </mat-checkbox> -->
      <div>
        <mat-checkbox class="text-primary font-medium -ml-3 mb-4 -mt-2" color="primary" formControlName="acceptedTerms"
        (change)="checkToDisableButton($event.checked)">
        <div class="text-primary font-medium text-base">You accept the 
          <a href="https://esdp-org.eu/terms-conditions/?_gl=1*1614eaw*_ga*NDQyMDMxMTM0LjE2OTk1MjE5NTI.*_ga_KNQDF1YSTT*MTcxMTAzMDkzOC45NC4wLjE3MTEwMzA5NDIuMC4wLjA."
          target="_blank" class="underline underline-offset-2"> Terms and Conditions</a>
        </div>
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox class="text-primary font-medium -ml-3 mb-4 -mt-2" color="primary" formControlName="acceptedPrivacyPolicy"
        (change)="checkToDisableButton($event.checked)">
        <div class="text-primary font-medium text-base">You accept the 
          <a href="https://esdp-org.eu/privacy/?_gl=1*1ozeso6*_ga*NDQyMDMxMTM0LjE2OTk1MjE5NTI.*_ga_KNQDF1YSTT*MTcxMTAzMDkzOC45NC4xLjE3MTEwMzEwNjUuMC4wLjA."
          target="_blank" class="underline underline-offset-2"> Privacy Policy</a>
        </div>
        </mat-checkbox>
      </div>

      <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" [ngModelOptions]="{standalone: true}" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
        <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
          <div *ngIf="recaptcha.errors?.['required']" class="text-red">This field is required.</div>
        </div>

        <div class="error-container" *ngIf="errorMessage">
          <span>{{ errorMessage }}</span>
          <button mat-icon-button (click)="clearError()">
            <mat-icon>close</mat-icon>
          </button>
        </div>

      <div class="button flex justify-end">
        <button type="submit" mat-raised-button class="mat-blue" [disabled]="!registerForm.valid || disableButton || recaptcha.invalid" (click)="createAccount()">Create Account</button>
      </div>

    </form>
  </div>
</div>
