import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../custom-validator';
import { TokenService } from '../../token/token.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent implements OnInit {
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  disableButton = false;
  isCreateAccountInProgress = false;
  token: string|undefined;
  errorMessage = '';
  countryCodes = [
    {
      "key": "Austria (Österreich) +43",
      "value": "+43"
    },
    {
      "key": "Belgium (België) +32",
      "value": "+32"
    },
    {
      "key": "Bulgaria (България) +359",
      "value": "+359"
    },
    {
      "key": "Croatia (Hrvatska) +385",
      "value": "+385"
    },
    {
      "key": "Cyprus (Κύπρος) +357",
      "value": "+357"
    },
    {
      "key": "Czech Republic (Česká republika) +420",
      "value": "+420"
    },
    {
      "key": "Denmark (Danmark) +45",
      "value": "+45"
    },
    {
      "key": "Estonia (Eesti) +372",
      "value": "+372"
    },
    {
      "key": "Finland (Suomi) +358",
      "value": "+358"
    },
    {
      "key": "France +33",
      "value": "+33"
    },
    {
      "key": "Germany (Deutschland) +49",
      "value": "+49"
    },
    {
      "key": "Greece (Ελλάδα) +30",
      "value": "+30"
    },
    {
      "key": "Hungary (Magyarország) +36",
      "value": "+36"
    },
    {
      "key": "Ireland +353",
      "value": "+353"
    },
    {
      "key": "Italy (Italia) +39",
      "value": "+39"
    },
    {
      "key": "Latvia (Latvija) +371",
      "value": "+371"
    },
    {
      "key": "Lithuania (Lietuva) +370",
      "value": "+370"
    },
    {
      "key": "Luxembourg +352",
      "value": "+352"
    },
    {
      "key": "Malta +356",
      "value": "+356"
    },
    {
      "key": "Netherlands (Nederland) +31",
      "value": "+31"
    },
    {
      "key": "Poland (Polska) +48",
      "value": "+48"
    },
    {
      "key": "Portugal +351",
      "value": "+351"
    },
    {
      "key": "Romania (România) +40",
      "value": "+40"
    },
    {
      "key": "Slovakia (Slovensko) +421",
      "value": "+421"
    },
    {
      "key": "Slovenia (Slovenija) +386",
      "value": "+386"
    },
    {
      "key": "Spain (España) +34",
      "value": "+34"
    },
    {
      "key": "Sweden (Sverige) +46",
      "value": "+46"
    }
  ]

  registerForm = this.fb.group({
    firstname: ['', [Validators.required, Validators.minLength(2)]],
    lastname: ['', [Validators.required, Validators.minLength(2)]],
    orgname: ['', [Validators.required, Validators.minLength(2)]],
    email: ['', [Validators.required, Validators.email]],
    email_confirm: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    password_confirm: ['', [Validators.required, Validators.minLength(8)]],
    phoneNumber: ['', Validators.required],
    countryCode: ['', Validators.required],
    // facingDisadvantage: [''],
    acceptedTerms: ['', Validators.required],
    acceptedPrivacyPolicy: ['', Validators.required]
  }, { validators: [CustomValidators.passwordsMatching, CustomValidators.emailsMatching] });
  
  isMobile = false;
  dropdownOpen = false;

  constructor(private authService: AuthService, private router: Router, private tokenService: TokenService,
    private fb: FormBuilder) {
      this.token = undefined;
    }

    ngOnInit(): void {
      this.tokenService.updateToken(null);
      this.logout();
    }

    async logout() {
      try {
      const response = await this.authService.logout();
      if (response) {
        if (response.success) {
          sessionStorage.clear();
          localStorage.clear();
        }
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      // Handle unexpected errors globally (optional)
    }
    }

    onDropdownOpen(isOpen: boolean): void {
      this.dropdownOpen = isOpen;
      this.toggleScrollLock();
    }

    private toggleScrollLock(): void {
      if (this.dropdownOpen) {
        document.body.classList.add('mat-select-panel-open');
      } else {
        document.body.classList.remove('mat-select-panel-open');
      }
    }

  async createAccount() {
    this.disableButton = true;
    this.isCreateAccountInProgress = true;
    try {
      const response = await this.authService.signUp(this.registerForm.get('email')?.value, this.registerForm.get('password')?.value, this.registerForm.value);
      if (response) {
        this.disableButton = false;
        this.isCreateAccountInProgress = false;
        if (response.success) {
          this.router.navigate(['/login']);
        } else {
          // this.errorMessage = response.response.message;
          this.errorMessage = 'Some error occured';
          this.disableButton = false;
          this.isCreateAccountInProgress = false;
           // Display error message
        }
      }
    } catch (error) {
      this.disableButton = false;
      this.isCreateAccountInProgress = false;
      this.errorMessage = 'Some error occured';
      console.error('Unexpected error:', error);
      // Handle unexpected errors globally (optional)
    }
  }

  clearError() {
    this.errorMessage = '';
  }

  checkToDisableButton(event: any) {
    if (event) {
      if (this.registerForm.get('acceptedTerms')?.value
      && this.registerForm.get('acceptedPrivacyPolicy')?.value)
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  gotoLogInPage(): void {
    this.router.navigate(['/login']);
  }

  goToLanding() {
    this.router.navigate(['/']);
  }
}
